<div class="row justify-content-center mt-3 mb-3">
    <div class="col-12 text-center">
      <h4>Treasurer</h4>
    </div>
  </div>
  <div class="row justify-content-center">
      <div class="col-3">
          <button mat-raised-button (click)='goCreate()'>Add New Member</button>
      </div>
      <div class="col-3">
          <button mat-raised-button (click)='goShow()'>View All Members</button>
      </div>
  </div>
  <div *ngIf='create' class="row mt-4">
      <div class="col-12">
          <div class="row">
              <div class="col-5 text-center heading mat-elevation-z8 coolfont"><h1>Add New Member</h1></div>
          </div>
          <div class="row">
              <div class="col-5 planform mat-elevation-z8">
                  <form [formGroup]="exampleForm" novalidate (ngSubmit)="addMember(exampleForm.value)">
                      <div class="row">
                          <div class="col col-12">
                              <div class="form-group">
                                  <mat-form-field>
                                    <input matInput placeholder="Name" class="form-control" formControlName="name">
                                  </mat-form-field>
                                  <ng-container *ngFor="let validation of validation_messages.name">
                                    <mat-error *ngIf="exampleForm.get('name').hasError(validation.type) && (exampleForm.get('name').dirty || exampleForm.get('name').touched)">{{validation.message}}</mat-error>
                                  </ng-container>
                              </div>
                              <div class="form-group">
                                  <mat-form-field>
                                    <input matInput placeholder="Contact No." class="form-control" formControlName="contact">
                                  </mat-form-field>
                              </div>
                              <div class="form-group">
                                  <mat-form-field>
                                      <mat-label>Zone</mat-label>
                                      <mat-select formControlName="zone">
                                          <mat-option *ngFor="let zone of zones" [value]="zone" >
                                              {{zone}}
                                          </mat-option>
                                      </mat-select>
                                  </mat-form-field>
                                  <ng-container *ngFor="let validation of validation_messages.zone">
                                    <mat-error *ngIf="exampleForm.get('zone').hasError(validation.type) && (exampleForm.get('zone').dirty || exampleForm.get('zone').touched)">{{validation.message}}</mat-error>
                                  </ng-container>
                              </div>
                            <div class="form-group">
                              <mat-form-field>
                                <input matInput type="number" placeholder="Rank" class="form-control" formControlName="rank">
                              </mat-form-field>
                              <ng-container *ngFor="let validation of validation_messages.rank">
                                <mat-error *ngIf="exampleForm.get('rank').hasError(validation.type) && (exampleForm.get('rank').dirty || exampleForm.get('rank').touched)">{{validation.message}}</mat-error>
                              </ng-container>
                          </div>
                              <div class="form-group">
                                      <div class="btn btn-outline-success pointer"><label for="image">Select Image</label></div> 
                                      <input type="file" accept="image/*" name="image" id='image' class="hidden" (change)=upload($event)>
                              </div>
                              <div>
                                <p *ngIf='showprog' style="color: orangered;">Image is being uploaded. Please wait.</p>
                              </div>
                          </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 mb-3">
                        <button mat-raised-button color="primary" type="submit" [disabled]="!exampleForm.valid">Add Member</button>
                      </div>
                    </div>
                  </form>
              </div>
              <div class="col-7" *ngIf='imgUploaded'>
                   <img id="memberimg" [src]="url">
              </div>
          </div>
      </div>
  </div>
  <div *ngIf='show' class="row mt-4">
      <div class="col-12">
          <div class="mat-elevation-z8">
              <table style="width: 100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> <h5>Name</h5> </th>
                    <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().name}} </td>
                  </ng-container>
  
                    <ng-container matColumnDef="contact">
                      <th mat-header-cell *matHeaderCellDef> <h5>Contact No.</h5> </th>
                      <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().contact}} </td>
                    </ng-container>

                    <ng-container matColumnDef="rank">
                      <th mat-header-cell *matHeaderCellDef> <h5>Rank</h5> </th>
                      <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().rank}} </td>
                    </ng-container>

                    <ng-container matColumnDef="zone">
                      <th mat-header-cell *matHeaderCellDef> <h5>Zone</h5> </th>
                      <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().zone}} </td>
                    </ng-container>
                  
                  
                  <ng-container matColumnDef="blank">
                    <th mat-header-cell *matHeaderCellDef> <h5>&nbsp;&nbsp;</h5> </th>
                    <td mat-cell *matCellDef="let element"> &nbsp;&nbsp; </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef> <h5>Image</h5> </th>
                    <td mat-cell *matCellDef="let element"> <img [src]="element.payload.doc.data().imgurl" alt="member image" class="memberimgsm"> </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> <h5>Actions</h5> </th>
                      <td mat-cell *matCellDef="let element"> 
                          <button class="btn btn-outline-success mat-elevation-z8" (click)="goEdit(element.payload.doc.id)">Edit</button>&nbsp;
                          <button class="btn btn-outline-danger mat-elevation-z8" (click)="goDelete(element.payload.doc.id)">Delete</button> 
                      </td>
                  </ng-container>
                
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                
          </div>
      </div>
  </div>
  <div *ngIf='edit' class="row mt-4">
      <div class="col-12">
          <div class="row">
              <div class="col-5 text-center heading mat-elevation-z8 coolfont"><h1>Edit Member</h1></div>
          </div>
          <div class="row mt-3">
              <div class="col-5 planform mat-elevation-z8">
                  <form [formGroup]="exampleForm" novalidate (ngSubmit)="updateMember(exampleForm.value)">
                      <div class="row">
                          <div class="col col-12">
                              <div class="form-group">
                                  <mat-form-field>
                                    <input matInput placeholder="Name" class="form-control" formControlName="name">
                                  </mat-form-field>
                                  <ng-container *ngFor="let validation of validation_messages.name">
                                    <mat-error *ngIf="exampleForm.get('name').hasError(validation.type) && (exampleForm.get('name').dirty || exampleForm.get('name').touched)">{{validation.message}}</mat-error>
                                  </ng-container>
                              </div>
                              <div class="form-group">
                                  <mat-form-field>
                                    <input matInput placeholder="Contact No." class="form-control" formControlName="contact">
                                  </mat-form-field>
                              </div>
                              <div class="form-group">
                                  <mat-form-field>
                                      <mat-label>Zone</mat-label>
                                      <mat-select formControlName="zone">
                                          <mat-option *ngFor="let zone of zones" [value]="zone" >
                                              {{zone}}
                                          </mat-option>
                                      </mat-select>
                                  </mat-form-field>
                                  <ng-container *ngFor="let validation of validation_messages.zone">
                                    <mat-error *ngIf="exampleForm.get('zone').hasError(validation.type) && (exampleForm.get('zone').dirty || exampleForm.get('zone').touched)">{{validation.message}}</mat-error>
                                  </ng-container>
                              </div>
                            <div class="form-group">
                              <mat-form-field>
                                <input matInput type="number" placeholder="Rank" class="form-control" formControlName="rank">
                              </mat-form-field>
                              <ng-container *ngFor="let validation of validation_messages.rank">
                                <mat-error *ngIf="exampleForm.get('rank').hasError(validation.type) && (exampleForm.get('rank').dirty || exampleForm.get('rank').touched)">{{validation.message}}</mat-error>
                              </ng-container>
                          </div>
                              <div class="form-group">
                                      <div class="btn btn-outline-success pointer"><label for="image">Change Image</label></div> 
                                      <input type="file" accept="image/*" name="image" id='image' class="hidden" (change)=upload($event)>
                              </div>
                              <div>
                                <p *ngIf='showprog' style="color: orangered;">Image is being uploaded. Please wait.</p>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-12 mb-3">
                              <div class="row">
                                  <div class="col-6">
                                      <button mat-raised-button color="primary" type="submit" [disabled]="!exampleForm.valid">Update Member</button>
                                      <!-- <button mat-raised-button color="accent" (click)='goShow()'>Cancel</button> -->
                                  </div>
                                  <div class="col-6">
                                      <!-- <button mat-raised-button color="primary" type="submit" [disabled]="!exampleForm.valid">Update Ad</button> -->
                                      <button mat-raised-button color="accent" (click)='goShow()'>Cancel</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </form>
              </div>
              <div class="col-7">
                   <img id="memberimg" [src]="url">
              </div>
          </div>
      </div>
  </div>