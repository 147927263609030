<div class="row justify-content-center text-center">
    <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-xl-12 mat-elevation-z8">
        <br>
        <form (ngSubmit) = 'onSubmit()'>
            <div class="form-group">
                <mat-form-field>
                    <input matInput placeholder="Username" name="uname" [(ngModel)]='uname'>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field>
                    <input matInput placeholder="Password" name="pass" [(ngModel)]='pass'>
                </mat-form-field>
            </div>
            <button mat-raised-button class="btn btn-light" type="submit">Login</button>
        </form>
        <br>
    </div>
</div>
