<div class="row justify-content-center mt-3 mb-3">
    <div class="col-12 text-center">
      <h4>Manage Donations</h4>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-3">
        <button mat-raised-button (click)='goCreate()'>Create Donation</button>
    </div>
    <div class="col-3">
        <button mat-raised-button (click)='goShow()'>View All Donations</button>
    </div>
</div>
<div *ngIf='create' class="row mt-4">
    <div class="col-12">
        <div class="row">
            <div class="col-5 text-center heading mat-elevation-z8 coolfont"><h1>Create Donation</h1></div>
        </div>
        <div class="row">
            <div class="col-5 planform mat-elevation-z8">
                <form [formGroup]="exampleForm" novalidate (ngSubmit)="createDonation(exampleForm.value)">
                    <div class="row">
                        <div class="col col-12">
                            <div class="form-group">
                                <mat-form-field>
                                  <input matInput placeholder="Donator's Name" class="form-control" formControlName="name">
                                </mat-form-field>
                                <ng-container *ngFor="let validation of validation_messages.name">
                                  <mat-error *ngIf="exampleForm.get('name').hasError(validation.type) && (exampleForm.get('name').dirty || exampleForm.get('name').touched)">{{validation.message}}</mat-error>
                                </ng-container>
                            </div>
                            <div class="form-group">
                                    <div class="btn btn-outline-success pointer"><label for="image">Select Image</label></div> 
                                    <input type="file" accept="image/*" name="image" id='image' class="hidden" (change)=upload($event)>
                            </div>
                            <div>
                                <p *ngIf='showprog' style="color: orangered;">Image is being uploaded. Please wait.</p>
                            </div>
                            <div class="form-group">
                                <mat-form-field style="width: 100%;">
                                  <textarea matInput placeholder="Address" class="form-control" formControlName="address"></textarea>
                                </mat-form-field>
                                <ng-container *ngFor="let validation of validation_messages.address">
                                  <mat-error *ngIf="exampleForm.get('address').hasError(validation.type) && (exampleForm.get('address').dirty || exampleForm.get('address').touched)">{{validation.message}}</mat-error>
                                </ng-container>
                            </div>
                            <div class="form-group">
                                <mat-form-field style="width: 100%;">
                                  <textarea matInput placeholder="Description" class="form-control" formControlName="description"></textarea>
                                </mat-form-field>
                                <ng-container *ngFor="let validation of validation_messages.description">
                                  <mat-error *ngIf="exampleForm.get('description').hasError(validation.type) && (exampleForm.get('description').dirty || exampleForm.get('description').touched)">{{validation.message}}</mat-error>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <button mat-raised-button color="primary" type="submit" [disabled]="!exampleForm.valid">Create Donator</button>
                        </div>
                    </div>
        
                </form>
            </div>
            <div class="col-7">
                 <img id="newsimg" [src]="url">
            </div>
        </div>
    </div>
</div>
<div *ngIf='show' class="row mt-4">
    <div class="col-12">
        <div class="mat-elevation-z8">
            <table style="width: 100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> <h5>Name</h5> </th>
                  <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().name}} </td>
                </ng-container>
                
                <ng-container matColumnDef="blank">
                  <th mat-header-cell *matHeaderCellDef> <h5>&nbsp;&nbsp;</h5> </th>
                  <td mat-cell *matCellDef="let element"> &nbsp;&nbsp; </td>
                </ng-container>
                
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef> <h5>Image</h5> </th>
                  <td mat-cell *matCellDef="let element"> <img [src]="element.payload.doc.data().imgurl" alt="ad image" class="newsimgsm"> </td>
                </ng-container>
                
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> <h5>Actions</h5> </th>
                    <td mat-cell *matCellDef="let element"> 
                        <button class="btn btn-outline-success mat-elevation-z8" (click)="goEdit(element.payload.doc.id)">Edit</button>&nbsp;
                        <button class="btn btn-outline-danger mat-elevation-z8" (click)="goDelete(element.payload.doc.id)">Delete</button> 
                    </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
              
        </div>
    </div>
</div>
<div *ngIf='edit' class="row mt-4">
    <div class="col-12">
        <div class="row">
            <div class="col-5 text-center heading mat-elevation-z8 coolfont"><h1>Edit Donation</h1></div>
        </div>
        <div class="row mt-3">
            <div class="col-5 planform mat-elevation-z8">
                <form [formGroup]="exampleForm" novalidate (ngSubmit)="updateDonation(exampleForm.value)">
                    <div class="row">
                        <div class="col col-12">
                            <div class="form-group">
                                <mat-form-field>
                                  <input matInput placeholder="Donator's Name" class="form-control" formControlName="name">
                                </mat-form-field>
                                <ng-container *ngFor="let validation of validation_messages.name">
                                  <mat-error *ngIf="exampleForm.get('name').hasError(validation.type) && (exampleForm.get('name').dirty || exampleForm.get('name').touched)">{{validation.message}}</mat-error>
                                </ng-container>
                            </div>
                            <div class="form-group">
                                    <div class="btn btn-outline-success pointer"><label for="image">Select Image</label></div> 
                                    <input type="file" accept="image/*" name="image" id='image' class="hidden" (change)=upload($event)>
                            </div>
                            <div>
                                <p *ngIf='showprog' style="color: orangered;">Image is being uploaded. Please wait.</p>
                            </div>
                            <div class="form-group">
                                <mat-form-field style="width: 100%;">
                                  <textarea matInput placeholder="Address" class="form-control" formControlName="address"></textarea>
                                </mat-form-field>
                                <ng-container *ngFor="let validation of validation_messages.address">
                                  <mat-error *ngIf="exampleForm.get('address').hasError(validation.type) && (exampleForm.get('address').dirty || exampleForm.get('address').touched)">{{validation.message}}</mat-error>
                                </ng-container>
                            </div>
                            <div class="form-group">
                                <mat-form-field style="width: 100%;">
                                  <textarea matInput placeholder="Description" class="form-control" formControlName="description"></textarea>
                                </mat-form-field>
                                <ng-container *ngFor="let validation of validation_messages.description">
                                  <mat-error *ngIf="exampleForm.get('description').hasError(validation.type) && (exampleForm.get('description').dirty || exampleForm.get('description').touched)">{{validation.message}}</mat-error>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-3">
                            <div class="row">
                                <div class="col-6">
                                    <button mat-raised-button color="primary" type="submit" [disabled]="!exampleForm.valid">Update Donator</button>
                                </div>
                                <div class="col-6">
                                    <button type="button" mat-raised-button color="accent" (click)='goShow()'>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-7">
                 <img id="newsimg" [src]="url">
            </div>
        </div>
    </div>
</div>