import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-matrimony',
  templateUrl: './matrimony.component.html',
  styleUrls: ['./matrimony.component.css']
})
export class MatrimonyComponent implements OnInit {

  constructor() {
  }


  ngOnInit(): void {
  }
}
