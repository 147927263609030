<div class="container">
    <div class="row justify-content-center mt-3">
        <div class="col-xl-4 col-lg-6 col-md-8 col-sm-12 text-center heading mat-elevation-z8"><h1>Sign In</h1></div>
    </div>
    <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-6 col-md-8 col-sm-12 mat-elevation-z8">
            <form class="planform text-center" [formGroup]="exampleForm" novalidate (ngSubmit)="signIn(exampleForm.value)">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput placeholder="E-mail" class="form-control" formControlName="uname">
                        </mat-form-field>
                        <ng-container *ngFor="let validation of validation_messages.uname">
                            <mat-error *ngIf="exampleForm.get('uname').hasError(validation.type) && (exampleForm.get('uname').dirty || exampleForm.get('uname').touched)">{{validation.message}}</mat-error>
                        </ng-container>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput placeholder="Password" type="password" class="form-control" formControlName="pwd">
                        </mat-form-field>
                        <ng-container *ngFor="let validation of validation_messages.pwd">
                            <mat-error *ngIf="exampleForm.get('pwd').hasError(validation.type) && (exampleForm.get('pwd').dirty || exampleForm.get('pwd').touched)">{{validation.message}}</mat-error>
                        </ng-container>
                    </div>
                </div>
                <div>
                    <button mat-raised-button class="signin" type="submit" [disabled]="!exampleForm.valid">Sign In</button>&nbsp;&nbsp;&nbsp;
                    <a href="#/resetpw">Forgot Password</a>
                </div>
                <br>
                <div>
                    <p style="border-top: 2px solid #0076C0;"></p>
                    <button mat-raised-button class="signup" (click)='goRegister()'>Or Register with us</button>
                </div>
                <br>
            </form>
        </div>
    </div>
</div>