<div class="row justify-content-center mt-3 mb-3">
  <div class="col-12 text-center">
    <h4>Manage Users</h4>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12">
      <div class="mat-elevation-z8">
        <div class="row">
            <div class="col-3">
                <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Filter Users">
                </mat-form-field>
            </div>
            <div class="col-9 usrfilter">
                <div class="row justify-content-center"><div class="col-3">Filter Users</div></div>
                <div class="row">
                    <div class="col-12">
                        <form class="text-center" [formGroup]="exampleForm" novalidate (ngSubmit)="filterUsers(exampleForm.value)">
                            <div class="row">
                                <div class="col-3">
                                    <mat-form-field>
                                        <mat-label>Religion</mat-label>
                                        <mat-select formControlName="religion">
                                            <mat-option *ngFor="let re of religions" [value]="re" >
                                                {{re}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <ng-container *ngFor="let validation of validation_messages.religion">
                                        <mat-error *ngIf="exampleForm.get('religion').hasError(validation.type) && (exampleForm.get('religion').dirty || exampleForm.get('religion').touched)">{{validation.message}}</mat-error>
                                    </ng-container>
                                </div>
                                <div class="col-3">
                                    <mat-form-field class="formstyl">
                                        <mat-label>Complexion</mat-label>
                                        <mat-select formControlName="complexion">
                                            <mat-option *ngFor="let co of complexions" [value]="co" >
                                                {{co}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <ng-container *ngFor="let validation of validation_messages.complexion">
                                        <mat-error *ngIf="exampleForm.get('complexion').hasError(validation.type) && (exampleForm.get('complexion').dirty || exampleForm.get('complexion').touched)">{{validation.message}}</mat-error>
                                    </ng-container>
                                </div>
                                <div class="col-3">
                                    <mat-form-field class="formstyl">
                                        <mat-label>Manglik/Non-Manglik</mat-label>
                                        <mat-select formControlName="manglik">
                                            <mat-option *ngFor="let mn of mangliks" [value]="mn" >
                                                {{mn}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <ng-container *ngFor="let validation of validation_messages.manglik">
                                        <mat-error *ngIf="exampleForm.get('manglik').hasError(validation.type) && (exampleForm.get('manglik').dirty || exampleForm.get('manglik').touched)">{{validation.message}}</mat-error>
                                    </ng-container>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-success" type="submit" [disabled]='!exampleForm.valid'>Apply Filters</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-2">
            <div class="col-2">
                <button class="btn btn-primary" (click)='resetFilters()'>Reset Filters</button>
            </div>
        </div>
          <table style="width: 100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> <h5>Name</h5> </th>
                <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> <h5>Email</h5> </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
              </ng-container>
              
              <ng-container matColumnDef="blank">
                <th mat-header-cell *matHeaderCellDef> <h5>&nbsp;&nbsp;</h5> </th>
                <td mat-cell *matCellDef="let element"> &nbsp;&nbsp; </td>
              </ng-container>
              
              <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef> <h5>Image</h5> </th>
                <td mat-cell *matCellDef="let element"> <img [src]="element.photoURL" alt="ad image" class="newsimgsm"> </td>
              </ng-container>
              
              <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> <h5>Actions</h5> </th>
                  <td mat-cell *matCellDef="let element"> 
                    <button class="btn btn-outline-primary mat-elevation-z8" (click)="goView(element.uid)">View</button>&nbsp;
                      <button class="btn btn-outline-success mat-elevation-z8" (click)="goEdit(element.uid)">Edit</button>&nbsp;
                  </td>
              </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            
      </div>
  </div>
</div>