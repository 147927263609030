<div class="row justify-content-center mt-3 mb-3">
    <div class="col-12 text-center">
      <h4>Manage Footer Ads</h4>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-3">
        <button mat-raised-button (click)='goCreate()'>Create Tall Ad</button>
    </div>
    <div class="col-3">
        <button mat-raised-button (click)='goShow()'>View Tall Ads</button>
    </div>
</div>
<div *ngIf='create' class="row mt-4">
    <div class="col-12">
        <div class="row">
            <div class="col-5 text-center heading mat-elevation-z8 coolfont"><h1>Create New Advertisement</h1></div>
            <div class="col-5 text-center">
                <p>Image width: 476 pixels, height: 1080 pixels</p>
            </div>
        </div>
        <div class="row">
            <div class="col-5 planform mat-elevation-z8 pt-4">
                <form [formGroup]="exampleForm" novalidate (ngSubmit)="createAdvert(exampleForm.value)">
                    <div class="row">
                        <div class="col col-12">
                            <div class="form-group">
                                    <div class="btn btn-outline-success pointer"><label for="image">Upload Image</label></div> 
                                    <input type="file" accept="image/*" name="image" id='image' class="hidden" (change)=upload($event)>
                            </div>
                            <div>
                                <p *ngIf='showprog' style="color: orangered;">Image is being uploaded. Please wait.</p>
                            </div>
                        </div>
                    </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <button mat-raised-button color="primary" type="submit" [disabled]="!exampleForm.valid">Create Ad</button>
                    </div>
                  </div>
                </form>
            </div>
            <div class="col-7">
                 <img class="adimg" [src]="url">
            </div>
        </div>
    </div>
</div>
<div *ngIf='show' class="row mt-4">
    <div class="col-12">
        <div class="mat-elevation-z8">
            <table style="width: 100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                
                <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef> <h5>Image</h5> </th>
                    <td mat-cell *matCellDef="let element"> <img [src]="element.payload.doc.data().imgurl" alt="ad image1" class="adimgsm"> </td>
                </ng-container>
                
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> <h5>Actions</h5> </th>
                    <td mat-cell *matCellDef="let element"> 
                        <button class="btn btn-outline-success mat-elevation-z8" (click)="goEdit(element.payload.doc.id)">Edit</button>&nbsp;
                        <button class="btn btn-outline-danger mat-elevation-z8" (click)="goDelete(element.payload.doc.id)">Delete</button> 
                    </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
              
        </div>
    </div>
</div>
<div *ngIf='edit' class="row mt-4">
    <div class="col-12">
        <div class="row">
            <div class="col-5 text-center heading mat-elevation-z8 coolfont"><h1>Edit Advertisement</h1></div>
            <div class="col-5 text-center">
                <p>Image width: 476 pixels, height: 1080 pixels</p>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-5 planform mat-elevation-z8 pt-4">
                <form [formGroup]="exampleForm" novalidate (ngSubmit)="updateAdvert(exampleForm.value)">
                    <div class="row">
                        <div class="col col-12">
                            <div class="col col-12">
                                <div class="form-group">
                                        <div class="btn btn-outline-success pointer"><label for="image">Change Image</label></div> 
                                        <input type="file" accept="image/*" name="image" id='image' class="hidden" (change)=upload($event)>
                                </div>
                                <div>
                                    <p *ngIf='showprog' style="color: orangered;">Image is being uploaded. Please wait.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-3">
                            <div class="row">
                                <div class="col-6">
                                    <button mat-raised-button color="primary" type="submit" [disabled]="!exampleForm.valid">Update Ad</button>
                                </div>
                                <div class="col-6">
                                    <button mat-raised-button color="accent" (click)='goShow()'>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-7">
                 <img class="adimg" [src]="url">
            </div>
        </div>
    </div>
</div>