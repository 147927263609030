<div class="row justify-content-center text-center mt-3">
    <div class="col-xl-8 col-lg-9 col-md-10 col-sm-11 col-xs-12">
        <div class="row justify-content-center">
            <div class="col-12 mat-elevation-z8 heading">
                <h1>Your Profile</h1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 text-center mat-elevation-z8 details pt-3">
                <div id="printprofile">
                    <img [src]="user.photoURL" class="profilepic mb-3" alt="profilepic" (click)='goFullpic()'>
                    <table class="table table-hover">
                        <tr>
                            <td>E-mail</td>
                            <td>{{user.email}}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{{user.displayName}}</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>{{user.address}}</td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td>{{user.phone}}</td>
                        </tr>
                        <tr>
                            <td>Date of Birth</td>
                            <td>{{user.dob}}</td>
                        </tr>
                        <tr>
                            <td>Time of Birth</td>
                            <td>{{user.tob}}</td>
                        </tr>
                        <tr>
                            <td>Place of Birth</td>
                            <td>{{user.pob}}</td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>{{user.gender}}</td>
                        </tr>
                        <tr>
                            <td>Caste</td>
                            <td>{{user.caste}}</td>
                        </tr>
                        <tr>
                            <td>Manglik/Non-Manglik</td>
                            <td>{{user.manglik}}</td>
                        </tr>
                        <tr>
                            <td>Height</td>
                            <td>{{user.feet}} feet {{user.inch}} inches</td>
                        </tr>
                        <tr>
                            <td>Complexion</td>
                            <td>{{user.complexion}}</td>
                        </tr>
                        <tr>
                            <td>Religion</td>
                            <td>{{user.religion}}</td>
                        </tr>
                        <tr>
                            <td>Educational Qualification</td>
                            <td>{{user.equ}}</td>
                        </tr>
                        <tr>
                            <td>Other Qualification</td>
                            <td>{{user.oqu}}</td>
                        </tr>
                        <tr>
                            <td>Occupation</td>
                            <td>{{user.occupation}}</td>
                        </tr>
                        <tr>
                            <td>Income</td>
                            <td>{{user.income}}</td>
                        </tr>
                        <tr>
                            <td>Present City</td>
                            <td>{{user.city}}</td>
                        </tr>
                        <tr>
                            <td>Preference of Life Partner</td>
                            <td>{{user.polp}}</td>
                        </tr>
                        <tr>
                            <td>Smoking Habit</td>
                            <td>{{user.smoke}}</td>
                        </tr>
                        <tr>
                            <td>Drinking Habit</td>
                            <td>{{user.drink}}</td>
                        </tr>
                        <tr>
                            <td>Hobbies</td>
                            <td>{{user.hobbies}}</td>
                        </tr>
                    </table>
                </div>
                <!-- <button mat-raised-button class="btn btn-primary mr-2 mb-3" (click)='goEditprofile()'>Edit Profile</button> -->
                <button [useExistingCss]="true" printSectionId="printprofile" class="btn btn-primary" ngxPrint>Print Profile</button>
                <button mat-raised-button class="btn btn-danger ml-2 mb-2" (click)='logOut()'>Log Out</button>     
            </div>
        </div>
    </div>
</div>
<div class="row justify-content-center mt-3">
    <div class="col-11">
        <div class="row justify-content-center mt-3">
            <div class="col-12 mat-elevation-z8 heading">
                <h1>Find your Match from among our Users</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mat-elevation-z8 details pt-3 tablewidth">
                <div class="row">
                    <div class="col-3">
                        <mat-form-field>
                            <mat-label>Search</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Filter Users">
                        </mat-form-field>
                    </div>
                    <div class="col-9 usrfilter">
                        <div class="row justify-content-center"><div class="col-3">Filter Users</div></div>
                        <div class="row">
                            <div class="col-12">
                                <form class="text-center" [formGroup]="exampleForm" novalidate (ngSubmit)="filterUsers(exampleForm.value)">
                                    <div class="row">
                                        <div class="col-3">
                                            <mat-form-field>
                                                <mat-label>Religion</mat-label>
                                                <mat-select formControlName="religion">
                                                    <mat-option *ngFor="let re of religions" [value]="re" >
                                                        {{re}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <ng-container *ngFor="let validation of validation_messages.religion">
                                                <mat-error *ngIf="exampleForm.get('religion').hasError(validation.type) && (exampleForm.get('religion').dirty || exampleForm.get('religion').touched)">{{validation.message}}</mat-error>
                                            </ng-container>
                                        </div>
                                        <div class="col-3">
                                            <mat-form-field class="formstyl">
                                                <mat-label>Complexion</mat-label>
                                                <mat-select formControlName="complexion">
                                                    <mat-option *ngFor="let co of complexions" [value]="co" >
                                                        {{co}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <ng-container *ngFor="let validation of validation_messages.complexion">
                                                <mat-error *ngIf="exampleForm.get('complexion').hasError(validation.type) && (exampleForm.get('complexion').dirty || exampleForm.get('complexion').touched)">{{validation.message}}</mat-error>
                                            </ng-container>
                                        </div>
                                        <div class="col-3">
                                            <mat-form-field class="formstyl">
                                                <mat-label>Manglik/Non-Manglik</mat-label>
                                                <mat-select formControlName="manglik">
                                                    <mat-option *ngFor="let mn of mangliks" [value]="mn" >
                                                        {{mn}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <ng-container *ngFor="let validation of validation_messages.manglik">
                                                <mat-error *ngIf="exampleForm.get('manglik').hasError(validation.type) && (exampleForm.get('manglik').dirty || exampleForm.get('manglik').touched)">{{validation.message}}</mat-error>
                                            </ng-container>
                                        </div>
                                        <div class="col-3">
                                            <button class="btn btn-success" type="submit" [disabled]='!exampleForm.valid'>Apply Filters</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mt-2">
                    <div class="col-2">
                        <button class="btn btn-primary" (click)='resetFilters()'>Reset Filters</button>
                    </div>
                    <div class="col-2 ml-2">
                        <button [useExistingCss]="true" printSectionId="divtoprint" class="btn btn-primary" ngxPrint>Print</button>
                    </div>
                </div>
                <div id="divtoprint">
                    <table mat-table matSort [dataSource]="dataSource">
            
                        <ng-container matColumnDef="blank">
                            <th mat-header-cell *matHeaderCellDef> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <td mat-cell *matCellDef="let element"> &nbsp;&nbsp; </td>
                          </ng-container>
            
                        <ng-container matColumnDef="photo">
                          <th mat-header-cell *matHeaderCellDef> Photo </th>
                          <td mat-cell *matCellDef="let element"> <img class="userimgsm" src="{{element.photoURL}}" alt="userImage"> </td>
                        </ng-container>
            
                        <ng-container matColumnDef="unlock">
                            <th mat-header-cell *matHeaderCellDef class="text-center"> View Full Profile </th>
                            <td mat-cell *matCellDef="let element" class="text-center"> <button class="btn btn-outline-success unlockbtn" (click)='viewProfile(element.uid)'><mat-icon> contact_phone</mat-icon></button> </td>
                          </ng-container>
            
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="gender">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
                          <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="complexion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Complexion </th>
                            <td mat-cell *matCellDef="let element"> {{element.complexion}} </td>
                          </ng-container>
                      
                        <ng-container matColumnDef="height">
                          <th mat-header-cell *matHeaderCellDef> Height </th>
                          <td mat-cell *matCellDef="let element"> {{element.feet}} feet {{element.inch}} inches</td>
                        </ng-container>
                        
                        <!-- <ng-container matColumnDef="caste">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Caste </th>
                          <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().caste}} </td>
                        </ng-container> -->
            
                        <ng-container matColumnDef="religion">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Religion </th>
                          <td mat-cell *matCellDef="let element"> {{element.religion}} </td>
                        </ng-container>
                      
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>