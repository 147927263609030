<div class="row justify-content-center">
    <div class="col-lg-6 col-md-8 col-sm-10 col-xs-11 text-center mat-elevation-z8">
        <h1 class="text-center heading pb-2">{{kkvak['title']}}</h1>
        <p>{{kkvak['para1']}}</p>
        <p>{{kkvak['para2']}}</p>
        <p>{{kkvak['para3']}}</p>
        <h5 class="text-right">- {{kkvak['author']}}</h5>
    </div>
</div>
<div class="row justify-content-center mt-4">
    <button class='btn btn-outline-primary' (click)='goNews()'>Go Back</button>
</div>