<div class="row justify-content-center">
    <div class="col-3">
        <h1>Admin Backend</h1>
    </div>
</div>
<div class="row">
    <div class="col-3">
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goCenterAds()'>Center Ads</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goTallAds()'>Tall Ads</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goFooterAds()'>Footer Ads</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goMessages()'>Messages</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goZones()'>Zones</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goDonations()'>Donations</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goDirectory()'>Directory</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goZonalBody()'>Zonal Body</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goNationalBody()'>National Body</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goNewMember()'>New Members</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goGoverningBody()'>Governing Body </button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goSanrakshakSadasya()'>Sanrakshak Sadsya</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goPresident()'>President</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goVicePresident()'>Vice President</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goSecretary()'>Secretary</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goJointSecretary()'>Joint Secretary</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goTreasurer()'>Treasurer</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goPro()'>PRO</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goExecutiveMembers()'>Executive Members</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goAnushashanSamiti()'>Anushashan Samiti</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goMahilaSamiti()'>Mahila Samiti</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goSadasyataVikasSamiti()'>Sadsyata Vikas Samiti</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goMediaPrabhandan()'>Media Prabhandan</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goUsers()'>Users</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goNews()'>News</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goEvents()'>Upcoming Events</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goPhotos()'>Photos</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goVideos()'>Videos</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goActivities()'>Activities</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button (click)='goKkvak()'>Karyakarni Vaktavya</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button style="background-color: rgba(209, 0, 0, 0.637);" (click)='logOut()'>Log Out</button>
            </div>
        </div>
    </div>
    <div class="col-9">
        <router-outlet></router-outlet>
    </div>
</div>