<div class="row justify-content-center">
    <div class="col-md-6 col-sm-8 col-xs-11">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center heading" style="background-color: #D86D35; color: white;">ACTIVITIES</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="mat-elevation-z8">
                    <table style="width: 100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <ng-container matColumnDef="title">
                            <td mat-cell *matCellDef="let element" class="text-center">
                                <div class="newshover" (click)="goFullActivity(element.payload.doc.id)">
                                    <img [src]="element.payload.doc.data().imgurl" alt="news image" class="newsimgsm"><br>
                                    <h2>{{element.payload.doc.data().title}}</h2>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>