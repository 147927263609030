<div class="row justify-content-center mt-3 mb-3">
  <div class="col-12 text-center">
    <h4>Manage Directory</h4>
  </div>
</div>
<div class="row justify-content-center">
    <div class="col-3">
        <button mat-raised-button (click)='goCreate()'>Add New Entry</button>
    </div>
    <div class="col-3">
        <button mat-raised-button (click)='goShow()'>View All Entries</button>
    </div>
</div>
<div *ngIf='create' class="row mt-4">
    <div class="col-12">
        <div class="row">
            <div class="col-5 text-center heading mat-elevation-z8 coolfont"><h1>Add New Entry</h1></div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 mat-elevation-z8">
                <form class="planform" [formGroup]="exampleForm" novalidate (ngSubmit)="goSubmit(exampleForm.value)">
                 Name: 
                    <div class="row mb-2">
                    <div class="col-12">
                      <input placeholder="Name" class="form-control" formControlName="name">
                    </div>
                  </div>
                  Address:
                  <div class="row mb-2">
                    <div class="col-12">
                      <textarea placeholder="Address" class="form-control" formControlName="address"></textarea>
                    </div>
                  </div>
                  Contact Number:
                  <div class="row mb-2">
                      <div class="col-12">
                          <input placeholder="Contact Number" type="number" class="form-control" formControlName="contact">
                      </div>
                  </div>
                  Birthday:
                  <div class="row mb-2">
                    <div class="col-2">
                      <input placeholder="Day" class="form-control" formControlName="bday">
                    </div>
                    <div class="col-3">
                      <input placeholder="Month" class="form-control" formControlName="bmonth">
                    </div>
                  </div>
                  Anniversary:
                  <div class="row mb-2">
                    <div class="col-2">
                      <input placeholder="Day" class="form-control" formControlName="aday">
                    </div>
                    <div class="col-3">
                      <input placeholder="Month" class="form-control" formControlName="amonth">
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-12">
                      <button mat-raised-button class="signin" type="submit" [disabled]="!exampleForm.valid">Add</button>
                    </div>
                  </div>
              </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf='show' class="row mt-4">
  <div class="col-12">
    <div class="row">
      <div class="col-4">
        <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search this Directory">
        </mat-form-field>
      </div>
    </div>
    <div class="mat-elevation-z8">
        <table style="width: 100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> <h5>Name</h5> </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef> <h5>Address</h5> </th>
              <td mat-cell *matCellDef="let element"> {{element.address}} </td>
            </ng-container>
            
            <ng-container matColumnDef="contact">
              <th mat-header-cell *matHeaderCellDef> <h5>Contact</h5> </th>
              <td mat-cell *matCellDef="let element"> {{element.contact}} </td>
            </ng-container>

            <ng-container matColumnDef="birthday">
                <th mat-header-cell *matHeaderCellDef> <h5>Birthday</h5> </th>
                <td mat-cell *matCellDef="let element"> {{element.bday}} {{element.bmonth}}</td>
              </ng-container>

            <ng-container matColumnDef="anniversary">
                <th mat-header-cell *matHeaderCellDef> <h5>Anniversary</h5> </th>
                <td mat-cell *matCellDef="let element"> {{element.aday}} {{element.amonth}}</td>
            </ng-container>
            
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> <h5>Actions</h5> </th>
                <td mat-cell *matCellDef="let element"> 
                  <button class="btn btn-outline-success mat-elevation-z8" (click)="goEdit(element.id)">Edit</button>&nbsp;
                    <button class="btn btn-outline-danger mat-elevation-z8" (click)="goDelete(element.id)">Delete</button>&nbsp;
                </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          
    </div>
  </div>
</div>
<div *ngIf='edit' class="row mt-4">
  <div class="col-12">
      <div class="row">
          <div class="col-5 text-center heading mat-elevation-z8 coolfont"><h1>Edit Entry</h1></div>
      </div>
      <div class="row justify-content-center">
          <div class="col-12 mat-elevation-z8">
              <form class="planform" [formGroup]="exampleForm" novalidate (ngSubmit)="updateDirectory(exampleForm.value)">
               Name: 
                  <div class="row mb-2">
                  <div class="col-12">
                    <input placeholder="Name" class="form-control" formControlName="name">
                  </div>
                </div>
                Address:
                <div class="row mb-2">
                  <div class="col-12">
                    <textarea placeholder="Address" class="form-control" formControlName="address"></textarea>
                  </div>
                </div>
                Contact Number:
                <div class="row mb-2">
                    <div class="col-12">
                        <input placeholder="Contact Number" type="number" class="form-control" formControlName="contact">
                    </div>
                </div>
                Birthday:
                <div class="row mb-2">
                  <div class="col-2">
                    <input placeholder="Day" class="form-control" formControlName="bday">
                  </div>
                  <div class="col-3">
                    <input placeholder="Month" class="form-control" formControlName="bmonth">
                  </div>
                </div>
                Anniversary:
                <div class="row mb-2">
                  <div class="col-2">
                    <input placeholder="Day" class="form-control" formControlName="aday">
                  </div>
                  <div class="col-3">
                    <input placeholder="Month" class="form-control" formControlName="amonth">
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <button mat-raised-button class="btn btn-outline-success mat-elevation-z8" type="submit" [disabled]="!exampleForm.valid">Update</button>
                    <button mat-raised-button class="btn btn-outline-danger mat-elevation-z8" [disabled]="!exampleForm.valid" (click)='goShow()'>Cancel</button>
                  </div>
                </div>
            </form>
          </div>
      </div>
  </div>
</div>