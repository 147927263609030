<div class="row justify-content-center mt-3 mb-3">
    <div class="col-12 text-center">
      <h4>Manage Footer Ads</h4>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-3">
        <button mat-raised-button (click)='goCreate()'>Create Footer Ad</button>
    </div>
    <div class="col-3">
        <button mat-raised-button (click)='goShow()'>View Footer Ads</button>
    </div>
</div>
<div *ngIf='create' class="row mt-4">
    <div class="col-12">
        <div class="row">
            <div class="col-5 text-center heading mat-elevation-z8 coolfont"><h1>Create New Advertisement</h1></div>
        </div>
        <div class="row">
            <div class="col-5 planform mat-elevation-z8 pt-4">
                <form [formGroup]="exampleForm" novalidate (ngSubmit)="createAdvert(exampleForm.value)">
                    <div class="row">
                        <div class="col col-12">
                            <div class="form-group">
                                    <div class="btn btn-outline-success pointer"><label for="image1">Upload Image 1</label></div> 
                                    <input type="file" accept="image/*" name="image1" id='image1' class="hidden" (change)=upload1($event)>
                            </div>
                            <div>
                                <p *ngIf='showprog1' style="color: orangered;">Image 1 is being uploaded. Please wait.</p>
                            </div>
                            <div class="form-group">
                                    <div class="btn btn-outline-success pointer"><label for="image2">Upload Image 2</label></div> 
                                    <input type="file" accept="image/*" name="image2" id='image2' class="hidden" (change)=upload2($event)>
                            </div>
                            <div>
                                <p *ngIf='showprog2' style="color: orangered;">Image 2 is being uploaded. Please wait.</p>
                            </div>
                            <div class="form-group">
                                    <div class="btn btn-outline-success pointer"><label for="image3">Upload Image 3</label></div> 
                                    <input type="file" accept="image/*" name="image3" id='image3' class="hidden" (change)=upload3($event)>
                            </div>
                            <div>
                                <p *ngIf='showprog3' style="color: orangered;">Image 3 is being uploaded. Please wait.</p>
                            </div>
                        </div>
                    </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <button mat-raised-button color="primary" type="submit" [disabled]="!exampleForm.valid">Create Ad</button>
                    </div>
                  </div>
        
                </form>
            </div>
            <div class="col-7">
                 <img class="adimg" [src]="url1">
                 <img class="adimg" [src]="url2">
                 <img class="adimg" [src]="url3">
            </div>
        </div>
    </div>
</div>
<div *ngIf='show' class="row mt-4">
    <div class="col-12">
        <div class="mat-elevation-z8">
            <table style="width: 100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                
                <ng-container matColumnDef="blank">
                  <th mat-header-cell *matHeaderCellDef> <h5>&nbsp;&nbsp;</h5> </th>
                  <td mat-cell *matCellDef="let element"> &nbsp;&nbsp; </td>
                </ng-container>
                
                <ng-container matColumnDef="image1">
                    <th mat-header-cell *matHeaderCellDef> <h5>Image1</h5> </th>
                    <td mat-cell *matCellDef="let element"> <img [src]="element.payload.doc.data().imgurl1" alt="ad image1" class="adimgsm"> </td>
                </ng-container>
                <ng-container matColumnDef="image2">
                    <th mat-header-cell *matHeaderCellDef> <h5>Image2</h5> </th>
                    <td mat-cell *matCellDef="let element"> <img [src]="element.payload.doc.data().imgurl2" alt="ad image2" class="adimgsm"> </td>
                </ng-container>
                <ng-container matColumnDef="image3">
                    <th mat-header-cell *matHeaderCellDef> <h5>Image3</h5> </th>
                    <td mat-cell *matCellDef="let element"> <img [src]="element.payload.doc.data().imgurl3" alt="ad image3" class="adimgsm"> </td>
                </ng-container>
                
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> <h5>Actions</h5> </th>
                    <td mat-cell *matCellDef="let element"> 
                        <button class="btn btn-outline-success mat-elevation-z8" (click)="goEdit(element.payload.doc.id)">Edit</button>&nbsp;
                        <button class="btn btn-outline-danger mat-elevation-z8" (click)="goDelete(element.payload.doc.id)">Delete</button> 
                    </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
              
        </div>
    </div>
</div>
<div *ngIf='edit' class="row mt-4">
    <div class="col-12">
        <div class="row">
            <div class="col-5 text-center heading mat-elevation-z8 coolfont"><h1>Edit Advertisement</h1></div>
        </div>
        <div class="row mt-3">
            <div class="col-5 planform mat-elevation-z8 pt-4">
                <form [formGroup]="exampleForm" novalidate (ngSubmit)="updateAdvert(exampleForm.value)">
                    <div class="row">
                        <div class="col col-12">
                            <div class="form-group">
                                <div class="btn btn-outline-success pointer"><label for="image1">Change Image 1</label></div> 
                                <input type="file" accept="image/*" name="image1" id='image1' class="hidden" (change)=upload1($event)>
                            </div>
                            <div>
                                <p *ngIf='showprog1' style="color: orangered;">Image 1 is being uploaded. Please wait.</p>
                            </div>
                            <div class="form-group">
                                <div class="btn btn-outline-success pointer"><label for="image2">Change Image 2</label></div> 
                                <input type="file" accept="image/*" name="image2" id='image2' class="hidden" (change)=upload2($event)>
                            </div>
                            <div>
                                <p *ngIf='showprog2' style="color: orangered;">Image 2 is being uploaded. Please wait.</p>
                            </div>
                            <div class="form-group">
                                <div class="btn btn-outline-success pointer"><label for="image3">Change Image 3</label></div> 
                                <input type="file" accept="image/*" name="image3" id='image3' class="hidden" (change)=upload3($event)>
                            </div>
                            <div>
                                <p *ngIf='showprog3' style="color: orangered;">Image 3 is being uploaded. Please wait.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-3">
                            <div class="row">
                                <div class="col-6">
                                    <button mat-raised-button color="primary" type="submit" [disabled]="!exampleForm.valid">Update Ad</button>
                                </div>
                                <div class="col-6">
                                    <button mat-raised-button color="accent" (click)='goShow()'>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-7">
                 <img class="adimg" [src]="url1">
                 <img class="adimg" [src]="url2">
                 <img class="adimg" [src]="url3">
            </div>
        </div>
    </div>
</div>