<div class="row justify-content-center mt-3 mb-3">
    <div class="col-12 text-center">
      <h4>Messages</h4>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
        <div class="mat-elevation-z8">
            <table style="width: 100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
                <ng-container matColumnDef="uname">
                  <th mat-header-cell *matHeaderCellDef> <h5>Email</h5> </th>
                  <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().uname}} </td>
                </ng-container>
                <ng-container matColumnDef="phn">
                  <th mat-header-cell *matHeaderCellDef> <h5>Contact</h5> </th>
                  <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().phn}} </td>
                </ng-container>
                
                <ng-container matColumnDef="message">
                  <th mat-header-cell *matHeaderCellDef> <h5>Message</h5> </th>
                  <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().message}} </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
              
        </div>
    </div>
  </div>