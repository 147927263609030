<div class="row justify-content-center">
  <div class="col-12 text-center">
    <h4>Directory</h4>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-4">
        <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search this Directory">
        </mat-form-field>
      </div>
    </div>
      <div class="mat-elevation-z8">
          <table style="width: 100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> <h5>Name</h5> </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
              <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef> <h5>Address</h5> </th>
                <td mat-cell *matCellDef="let element"> {{element.address}} </td>
              </ng-container>
              
              <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef> <h5>Contact</h5> </th>
                <td mat-cell *matCellDef="let element"> {{element.contact}} </td>
              </ng-container>

              <ng-container matColumnDef="birthday">
                  <th mat-header-cell *matHeaderCellDef> <h5>Birthday</h5> </th>
                  <td mat-cell *matCellDef="let element"> {{element.bday}} {{element.bmonth}}</td>
                </ng-container>

              <ng-container matColumnDef="anniversary">
                  <th mat-header-cell *matHeaderCellDef> <h5>Anniversary</h5> </th>
                  <td mat-cell *matCellDef="let element"> {{element.aday}} {{element.amonth}}</td>
              </ng-container>
              
              <!-- <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> <h5>Actions</h5> </th>
                  <td mat-cell *matCellDef="let element"> 
                      <button class="btn btn-outline-success mat-elevation-z8" (click)="goDelete(element.payload.doc.id)">Delete</button>&nbsp;
                  </td>
              </ng-container> -->
            
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            
      </div>
  </div>
</div>