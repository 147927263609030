<div class="row justify-content-center mt-3 mb-3">
    <div class="col-12 text-center">
      <h4>Manage New Members (Sadasyata)</h4>
    </div>
</div>
<div class="row mt-4">
    <div class="col-12">
        <div class="mat-elevation-z8">
            <table style="width: 100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> <h5>Name</h5> </th>
                    <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().name}} </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> <h5>Email</h5> </th>
                    <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().email}} </td>
                </ng-container>
                <ng-container matColumnDef="photo">
                    <th mat-header-cell *matHeaderCellDef> <h5>Photo</h5> </th>
                    <td mat-cell *matCellDef="let element"><img src="{{element.payload.doc.data().photoURL}}" class="photo"></td>
                </ng-container>
                <ng-container matColumnDef="contact">
                    <th mat-header-cell *matHeaderCellDef> <h5>Contact No.</h5> </th>
                    <td mat-cell *matCellDef="let element"> {{element.payload.doc.data().mobile}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> <h5>Actions</h5> </th>
                    <td mat-cell *matCellDef="let element"> 
                        <button class="btn btn-outline-primary mat-elevation-z8" (click)="goDetails(element.payload.doc.id)">Details</button>&nbsp;&nbsp;
                        <button class="btn btn-outline-danger mat-elevation-z8" (click)="goDelete(element.payload.doc.id)">Delete</button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>